<template>
  <b-card-code>
    <div class="row mb-2">
      <div class="col-lg-6">
        <h4>Rendelések</h4>
      </div>
      <div class="col-lg-6 text-right">
        <button class="btn btn-primary mr-0 mr-md-2" @click="$router.push( {name:'monolit.order.own.archive'} )">Archivált rendelések</button>
      </div>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'inside_order'"
        >
          <span v-if="props.row.inside_order == 1">Belsős rendelés</span>
          <span v-else-if="props.row.inside_order == 0">Külsős rendelés</span>
          <span v-else-if="props.row.inside_order == 2">Raktár feltöltés</span>
        </div>

        <div
          v-else-if="props.column.field === 'accepted'"
        >
          <span class="badge badge-success" style=";width:150px;color:white" v-if="props.row.accepted == true">Elfogadva</span>
          <span class="badge badge-danger" style=";width:150px;color:white" v-else-if="props.row.disabled == true">Elutasítva</span>
          <span class="badge badge-warning" style=";width:150px;color:white" v-else>Feldolgozás alatt</span>
        </div>

        <div
          v-else-if="props.column.field === 'accepted_warehouse'"
        >
          <div v-if="props.row.inside_order !== 2">
            <span class="badge badge-success" style=";width:150px;color:white" v-if="props.row.accepted_warehouse == true">Kiküldve</span>
            <span class="badge badge-danger" style=";width:150px;color:white" v-else-if="props.row.disabled_warehouse == true">Elutasítva</span>
            <span class="badge badge-warning" style=";width:150px;color:white" v-else>Feldolgozás alatt</span>
          </div>
        </div>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge style=";width:150px;color:white" :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item v-b-modal.modal-edit @click="loadOrderData(props.row.id)">
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-50"
                />
                <span>Feldolgozás</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="props.row.inside_order == 2" @click="archive(props.row.id)">
                <feather-icon
                  icon="BoxIcon"
                  class="mr-50"
                />
                <span>Archiválás</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Mutasd 1-től
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['25','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap ">-ig a {{ props.total }} elemet </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal id="modal-edit" size="lg" ref="modal-edit" title="Rendelés adatai" hide-footer>
      <div class="row">
        <div class="col-lg-12 mt-2">
          <div class="row">
            <div class="col-lg-6">
              <p class="mb-0">Megrendelő neve: {{ selected.creator_name }}</p>
              <p class="mb-0">Projekt: {{ selected.project_name }}</p>
              <p class="mb-0" v-if="selected.inside_order !== 2">Szállítás helye: {{ selected.place }}</p>
              <p class="mb-0" v-if="selected.inside_order !== 2">Rendelést átveszi: {{ selected.receiver_name }}</p>
              <p class="mb-0" v-if="selected.inside_order !== 2">Mikorra szeretné: {{ selected.order_date }}</p>
              <p class="mb-0" v-if="selected.order_deliver_started">Várható kiszállítás: {{ selected.order_deliver_started_date }}</p>
              <p class="mb-0">Megjegyzés: {{ selected.comment }}</p>
            </div>
            <div class="col-lg-6" v-show="selected.inside_order == 0">
              <div v-if="checkID(selected.target_id)">
                <b-form-group
                  label="Aktuális felelős"
                  label-for="order-project"
                >
                  <b-form-select
                    id="order-project"
                    v-model="target_id"
                    :options="targetOptions"
                    text-field="name"
                    value-field="id"
                  />
                </b-form-group>
                <p class="float-right">
                  <button class="btn btn-primary btn-sm" @click="changeTargetID()">Módosítás</button>
                </p>
              </div>
              <div v-else>Aktuális felelős<br><span class="badge badge-info">{{selected.target_name}}</span>
              </div>
            </div>
          </div>

          <p class="mt-3"><b>Rendelt termékek</b></p>
          <div id="print_div">
            <table class="table mb-2">
              <thead>
                <tr>
                  <th scope="col">Termék</th>
                  <th scope="col">Mennyiség</th>
                  <th scope="col">Mennyiségi egység</th>
                  <th class="text-center" v-if="selected.accepted == true && selected.accepted_warehouse == true && checkID(selected.receiver_id) && selected.inside_order != 2" scope="col">Teljesítve</th>
                  <th class="text-center" scope="col" v-if="selected.inside_order == 2">Teljesítve</th>
                </tr>
              </thead>
              <tr v-for="(product, index) in selected.items" :key="index">
                <td>
                  {{ product.product }}
                </td>
                <td>
                  {{ product.quantity }}
                </td>
                <td>
                  {{ product.unit }}
                </td>
                <td class="text-center" v-if="selected.accepted == true && selected.accepted_warehouse == true && checkID(selected.receiver_id) && selected.inside_order != 2">
                  <span class="badge badge-success" style="cursor:pointer" @click="changeItemStatus(index)" v-if="product.done"><feather-icon
                    icon="CheckCircleIcon"
                    size="18"
                  /></span>
                  <span class="badge badge-danger" style="cursor:pointer" @click="changeItemStatus(index)" v-else><feather-icon
                    icon="MinusCircleIcon"
                    size="18"
                  /></span>
                </td>
                <td class="text-center" v-if="selected.inside_order == 2">
                  <span class="badge badge-success" style="cursor:pointer" v-if="product.done"><feather-icon
                    icon="CheckCircleIcon"
                    size="18"
                  /></span>
                  <span class="badge badge-danger" style="cursor:pointer" v-else><feather-icon
                    icon="MinusCircleIcon"
                    size="18"
                  /></span>
                </td>
              </tr>
            </table>
          </div>
          <div class="col-lg-12">
            <button class="btn btn-primary float-left" v-if="((selected.inside_order == 1 || selected.inside_order == 2) && selected.status != 'Teljesítve' && selected.status != 'Kiszállítva' && selected.status != 'Elfogadva')" @click="notificationNewProduct()">Értesítés a rendelés változásról</button>
            <button class="btn btn-primary float-right"  @click="print()">Rendelt termékek nyomtatása</button>
          </div>
          <div class="row col-lg-12 pt-2" v-if="((selected.inside_order == 1 || selected.inside_order == 2) && selected.status != 'Teljesítve' && selected.status != 'Kiszállítva' && selected.status != 'Elfogadva')">
            <p class="col-lg-12"><b>Termék rögzítése</b></p>
            <div class="col-lg-4">
              <b-form-group
                label="Termék neve"
                label-for="product"
              >
                <vue-autosuggest
                  v-model="product"
                  @selected="setProduct"
                  :suggestions="filteredOptions2"
                  :limit="10"
                  :input-props="{id:'autosuggest__input2',class:'form-control', placeholder:'Termék neve'}"
                  @input="onInputChange2"
                >
                  <template slot-scope="{suggestion}">
                    <span class="my-suggestion-item">{{ suggestion.item.product }}</span>
                  </template>
                </vue-autosuggest>
              </b-form-group>
            </div>
            <div class="col-lg-4">
              <b-form-group
                label="Mennyiség"
                label-for="quantity"
              >
                <b-form-input
                  id="quantity"
                  v-model="quantity"
                  placeholder="Mennyiség"
                />
              </b-form-group>
            </div>
            <div class="col-lg-3">
              <b-form-group
                label="Mennyiségi egység"
                label-for="unit"
              >
                <vue-autosuggest
                  v-model="unit"
                  @selected="setUnit"
                  :suggestions="filteredOptions"
                  :limit="10"
                  :input-props="{id:'autosuggest__input',class:'form-control', placeholder:'Mennyiségi egység'}"
                  @input="onInputChange"
                >
                  <template slot-scope="{suggestion}">
                    <span class="my-suggestion-item">{{ suggestion.item.supplier }}</span>
                  </template>
                </vue-autosuggest>
              </b-form-group>
            </div>
            <div class="col-lg-1">
              <br>
              <button class="btn btn-primary w-100" @click="addItem()">
                <feather-icon
                  icon="PlusCircleIcon"
                  size="16"
                />
              </button>
            </div>
          </div>
          <b-row v-if="materials.length > 0">
            <b-col md="12" class="mt-3"><b>Elérhető tételek a projekthez</b></b-col>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Termék</th>
                  <th scope="col">Mennyiség (elérhető / összesen)</th>
                  <th scope="col">Mértékegység</th>
                </tr>
              </thead>
              <tr v-for="(material, index) in materials" :key="index">
                <td>
                  {{ material.product }}
                </td>
                <td>
                  {{ material.quantity }} / {{ material.total }}
                </td>
                <td>
                  {{ material.unit }}
                </td>
              </tr>
            </table>
          </b-row>
          <p class="mt-3">
            <b>Megjegyzések</b>
          </p>
          <b-form-group
            label="Megjegyzés írása"
            label-for="write-comment"
          >
            <b-form-textarea
              id="write-comment"
              v-model="new_comment"
            />
          </b-form-group>
          <button class="btn btn-primary mb-2" @click="addComment(selected.id)">Küldés</button>
          <hr>
          <div v-for="(comment, index) in selected.comment_array" :key="index">
            <p><b>{{ comment.user }}</b><br><small>{{ comment.date }}</small><br>{{ comment.comment }}</p>
            <hr>
          </div>
        </div>
      </div>
      <div class="row justify-content-between mt-3">
        <div>
          <button
            class="mr-2 btn"
            @click="$bvModal.hide('modal-edit')"
          >
            Bezár
          </button>
        </div>
        <div v-if="selected.accepted == true && selected.accepted_warehouse == true && checkID(selected.receiver_id) && selected.inside_order != 2">
          <button
            class="mr-2 btn btn-primary"
            @click="sendFeedback(selected.id)"
          >
            Visszaigazolás
          </button>
        </div>
      </div>
    </b-modal>
  </b-card-code>
</template>

<script>
import '@core/scss/vue/libs/vue-autosuggest.scss'
import { VueAutosuggest } from 'vue-autosuggest'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BBadge, BPagination, BFormSelect, BDropdown, BDropdownItem, BFormGroup, BFormTextarea, BFormFile, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormTextarea,
    BFormFile,
    BFormInput,
    BFormCheckbox,
    VueAutosuggest,
  },
  data() {
    return {
      materials: [],
      selected: [],
      pageLength: 25,
      dir: false,
      targetOptions: [],
      target_id: '',
      company_name: '',
      company_price: '',
      file: null,
      new_comment: '',
      columns: [
        {
          label: 'Rendelés típusára',
          field: 'inside_order',
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: 'Projekt neve',
          field: 'project_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés projektre',
          },
        },
        {
          label: 'Helyszín',
          field: 'place',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés helyszínre',
          },
        },
        {
          label: 'Státusz',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés névre',
          },
        },
        {
          label: 'Vezetőségi visszajelzés',
          field: 'accepted',
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: 'Raktár visszajelzés',
          field: 'accepted_warehouse',
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: '',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      offerItems: [],
      unit: '',
      product: '',
      quantity: '',
      filteredOptions: [],
      filteredOptions2: [],
      datasuggest: [],
      datasuggest2: [],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        Folyamatban: 'light-success',
        Késésben: 'light-warning',
        Lejárt: 'light-danger',
        Kész: 'light-info',
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.loadOrderList(this.$route.params.id)
      this.$bvModal.show('modal-edit')
    } else {
      this.loadOrderList(0)
    }
    this.getTargetOptions()
    this.getReceiverOptions()
    this.loadProduct()
    this.loadSupplier()
  },
  methods: {
    loadSupplier() {
      axios({
        method: 'get',
        url: 'order/autocomplete/supplier',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        res.data.forEach(supplier => {
          this.datasuggest.push({
            supplier: supplier.supplier,
            name: supplier.supplier,
          })
        })
      })
    },
    addItem() {
      if (this.product === '' || this.quantity === '' || this.unit === '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Minden mező kitöltése kötelező',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      } else {
        this.selected.items.push({
          product: this.product,
          quantity: this.quantity,
          unit: this.unit,
          done: false,
        })
        this.product = ''
        this.quantity = ''
        this.unit = ''

        axios({
          method: 'post',
          url: 'order/update/items',
          data: {
            id: this.selected.id,
            items: this.selected.items,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
      }
    },
    notificationNewProduct() {
      axios({
        method: 'post',
        url: `order/update/items/notification/${this.selected.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Értesítés kiküldve!',
          icon: 'XOctagonIcon',
          variant: 'success',
        },
      })
      axios({
        method: 'get',
        url: `order/available-materials/${this.selected.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.materials = res.data
      })
    },
    loadProduct() {
      axios({
        method: 'get',
        url: 'order/autocomplete/product',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        res.data.forEach(product => {
          this.datasuggest2.push({
            product: product.product,
            name: product.product,
          })
        })
      })
    },
    getReceiverOptions() {
      axios({
        method: 'get',
        url: 'role/list/create_order',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.receiverOptions = res.data
        this.receiver_id = JSON.parse(localStorage.getItem('user')).id
      })
    },
    setUnit(text) {
      this.unit = text.item.name
    },
    setProduct(text) {
      this.product = text.item.name
    },
    onInputChange(text) {
      if (text === '' || text === undefined) {
        return
      }
      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredDevelopers = this.datasuggest.filter(item => item.supplier.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      const filteredData = filteredDevelopers
      this.filteredOptions = [{
        data: filteredData,
      }]
    },
    onInputChange2(text) {
      if (text === '' || text === undefined) {
        return
      }
      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredDevelopers = this.datasuggest2.filter(item => item.product.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      const filteredData = filteredDevelopers
      this.filteredOptions2 = [{
        data: filteredData,
      }]
    },
    print() {
      const prtHtml = document.getElementById('print_div').innerHTML
      // Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')

      let selectedFiles = null

      if (this.selected.files != null) {
        selectedFiles = this.selected.files.filter(file => file.accepted === true)
      }

      console.log(selectedFiles)

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          <style>
            body{
              font-size: 26px;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              text-align:center;
            }
            .mb-0{
              margin-bottom: 0px;
            }
          </style>
        </head>
        <body>
          <div style="background:gray;">
            <p class="mb-0">Megrendelő neve: ${this.selected.creator_name}</p>
            <p class="mb-0">Projekt: ${this.selected.project_name}</p>
            <p class="mb-0">Szállítás helye: ${this.selected.place}</p>
            <p class="mb-0">Rendelést átveszi: ${this.selected.receiver_name}</p>
            <p class="mb-0">Mikorra szeretné: ${this.selected.order_date}</p>
            <p class="mb-0">Megjegyzés: ${this.selected.comment}</p>
            <hr>
          </div>
          ${prtHtml}
        </body>
      </html>`)

      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    },
    changeOfferData(index) {
      this.offerItems[index].inOffer = !this.offerItems[index].inOffer
    },
    checkID(id) {
      return JSON.parse(localStorage.getItem('user')).id === id
    },
    checkRole(roles) {
      return JSON.parse(localStorage.getItem('user')).role.includes(roles)
    },
    changeFile(event) {
      this.file = event.target.files[0]
    },
    getTargetOptions() {
      axios({
        method: 'get',
        url: 'role/list/order_target',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.targetOptions = res.data
      })
    },
    addFile() {
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('company_name', this.company_name)
      formData.append('company_price', this.company_price)
      this.offerItems.forEach((item) => {
        formData.append('offer_items[]', JSON.stringify(item))
      })
      axios({
        method: 'post',
        url: `order/upload-file/${this.selected.id}`,
        data: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        responseType: 'blob',
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen feltölve',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.file = null
        this.company_name = ''
        this.company_price = ''
        this.$refs.file.value = null
        this.loadOrderList(this.selected.id)
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: Object.values(error.response.data.errors)[0][0],
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    changeTargetID() {
      axios({
        method: 'put',
        url: `order/change-target-id/${this.selected.id}`,
        data: {
          target_id: this.target_id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.loadOrderList(this.selected.id)
      })
    },
    addComment(id) {
      if (this.new_comment == '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Adjon meg kommentet',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      } else {
        axios({
          method: 'post',
          url: `order/new-comment/${id}`,
          data: {
            comment: this.new_comment,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).then(response => {
          this.selected.comment_array = response.data
          this.new_comment = ''
        })
      }
    },
    changeOfferStatus(index, status) {
      axios({
        method: 'put',
        url: `order/change_offer_type/${this.selected.id}/${index}`,
        data: {
          status,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.loadOrderList(this.selected.id)
      })
    },
    changeItemStatus(index) {
      this.selected.items[index].done = !this.selected.items[index].done
    },
    loadOrderList(id) {
      axios({
        method: 'get',
        url: 'order/own',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.rows = response.data
        if (id !== 0) {
          this.selected = this.rows.find(order => order.id == id)
          this.target_id = this.selected.target_id
          this.offerItems = this.selected.items
          this.offerItems.forEach(item => {
            item.available = 0
            item.inOffer = false
          })
        }
      })
    },
    loadOrderData(id) {
      this.selected = this.rows.find(row => row.id === id)
      this.offerItems = this.selected.items
      this.offerItems.forEach(item => {
        item.available = 0
        item.inOffer = false
      })

      axios({
        method: 'get',
        url: `order/available-materials/${this.selected.product_id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.materials = res.data
      })
    },
    sendFeedback(id) {
      axios({
        method: 'post',
        url: `order/feedback/${id}`,
        data: {
          items: this.selected.items,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.loadOrderList(id)
        this.$bvModal.hide('modal-edit')
      })
    },
    archive(id) {
      axios({
        method: 'put',
        url: `order/archive/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.loadOrderList(0)
      })
    }
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
<style>
  .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn::after{
    content: "►" !important;
  }
  .vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn::before{
    content: "◄" !important;
  }
  .border-bottom-own{
    border-bottom: 1px solid #7a7a7a !important;
    padding-bottom: 5px;
    padding-top: 5px;
  }
  .bg-light-primary{
    background-color: #343D55 !important;
    border-radius: 10px;
  }
</style>
